




























































































































import Vue from 'vue';
const Ripple = require('vue-ripple-directive');
import axios from 'axios';
import { MonitorType, ThresholdConfig } from './types';

interface EditMonitorType extends MonitorType {
  dirty?: boolean;
}

export default Vue.extend({
  components: {},
  directives: {
    Ripple,
  },
  data() {
    const columns = [
      {
        key: '_id',
        label: '代碼',
      },
      {
        key: 'desp',
        label: '名稱',
        sortable: true,
      },
      {
        key: 'unit',
        label: '單位',
        sortable: true,
      },
      {
        key: 'prec',
        label: '小數點位數',
        sortable: true,
      },
      {
        key: 'std_law',
        label: '法規值',
        sortable: true,
      },
      {
        key: 'thresholdConfig',
        label: '超標噴水時間',
        sortable: true,
      },
      {
        key: 'alarmPauseTime',
        label: '噴水暫停時間',
        sortable: true,
      },
      {
        key: 'alarmWarnTime',
        label: '噴水警告時間',
        sortable: true,
      },
    ];
    const monitorTypes = Array<EditMonitorType>();

    let thresholdConfig: ThresholdConfig = {
      elapseTime: 30,
    };

    const form = {
      thresholdConfig,
    };
    return {
      display: false,
      columns,
      monitorTypes,
      editingMt: {
        thresholdConfig: {},
      },
      form,
    };
  },
  mounted() {
    this.getMonitorTypes();
  },
  methods: {
    getMonitorTypes() {
      axios.get('/MonitorType').then(res => {
        this.monitorTypes = res.data;
        for (const mt of this.monitorTypes) {
          if (mt.thresholdConfig === undefined)
            mt.thresholdConfig = { elapseTime: 30 };
        }
      });
    },
    justify(mt: any) {
      if (mt.span === '') mt.span = null;
      if (mt.span_dev_internal === '') mt.span_dev_internal = null;
      if (mt.span_dev_law === '') mt.span_dev_law = null;
      if (mt.zd_internal === '') mt.zd_internal = null;
      if (mt.zd_law === '') mt.zd_law = null;
      if (mt.std_internal === '') mt.std_internal = null;
      if (mt.std_law === '') mt.std_law = null;
    },
    save() {
      const all = [];
      for (const mt of this.monitorTypes) {
        if (mt.dirty) {
          this.justify(mt);
          console.info('monitorType to be updated', mt);
          all.push(axios.put(`/MonitorType/${mt._id}`, mt));
        }
      }
      Promise.all(all).then(() => {
        this.getMonitorTypes();
        this.$bvModal.msgBoxOk('成功');
      });
    },
    markDirty(item: any) {
      item.dirty = true;
    },
    setMtThresholdConfig() {
      this.editingMt.thresholdConfig = this.form.thresholdConfig;
      this.markDirty(this.editingMt);
    },
  },
});
